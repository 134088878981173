import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../css/Section1b.css';


function Section1b() {
  return (
    <div className="App-content-section1b">
      <div className="Sec1b-left">
        <div className="Section1b-img">
          <img src={`${process.env.PUBLIC_URL}/images/Section1b_screenshot.png`} alt="Sec1b 1"/>
        </div>
      </div>

      <div className="Sec1b-right">
        <div className = "Sec1b-title1">
            WORK ORDERS
        </div>
        <div className = "Sec1b-title2">
            MADE EASY
        </div>
        <div className="Section1b-text1">
            Submit change orders effortlessly. Get approval seemlessly.
        </div>
        <div className="Section1b-text2">
            With AddWork's simple and intuitive design, making a change order or work 
            order immediately while on-site is simple.  Job site changes happen 
            daily, and in real time.
        </div>
        <div className="Section1b-lm">
          <Link to="/about" className="S1b-learn-more-button">LEARN MORE</Link>
        </div>
      </div>
    </div>
  );
}

export default Section1b;