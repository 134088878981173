import React from 'react';
import Header from './Header';
import Section5 from './Section5';
import Section1_OurStory from './Section1_OurStory'; // Import the customized Section1 for Our Story
import Section2_OurStory from './Section2_OurStory'; // Import the customized Section1 for Our Story
import Section3_OurStory from './Section3_OurStory'; // Import the customized Section1 for Our Story
import '../css/OurStory.css';

function OurStory() {
  return (
    <div className="OurStory-page">
      <Header />
    <div className="OurStory-main">
      <Section1_OurStory />
      <Section2_OurStory />
      <Section3_OurStory />
      <Section5 />
    </div>
    </div>
  );
}

export default OurStory;
