import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Section1_BEntry1.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

function Blog1() {
  return (
    <div className="App-content-Blogsection1">
      <header className="Section1-headerb1">
        <nav className="App-navb1">
          <div className="App-logob1">
            <Link to="/">
              <img
                src={`${process.env.PUBLIC_URL}/images/AddWrk_logo_red.png`}
                alt="ADDWRK Logo"
              />
            </Link>
          </div>
          <ul className="App-menub1">
            <li><Link to="/our-story">OUR STORY</Link></li>
            <li><Link to="/about">ABOUT</Link></li>
            <li><Link to="/blog">BLOG</Link></li>
            <li><Link to="/contact">CONTACT</Link></li>
          </ul>
          <div className="Merch-download-signinb1">
            <Link to="/company">
              <img
                src={`${process.env.PUBLIC_URL}/images/Download_buttonh.png`}
                alt="Download Button"
                className="Downloadb1"
              />
            </Link>
            <Link to="/company" className="Signinb1">SIGN IN</Link>
            <div className="Merch-iconb1">
              <FontAwesomeIcon icon={faCartShopping} />
            </div>
          </div>
        </nav>
      </header>

      <div className="App-content-section1-entry1">
        <div className="Section1-entry1-background">
          <img
            src={`${process.env.PUBLIC_URL}/images/blog1_pic.png`}
            alt="Blog Background"
            className="entry1-image"
          />
        </div>
        <div className="Section1-entry1-content">
          <h1 className="Page-entry1-title">FEATURED BLOG</h1>
          <div className="entry1-title-holder">
            <h2 className="entry1-feature-title">Tug-of-War | </h2>
            <h3 className="entry1-feature-title2">ARE WE SCREWED?<br />Inflation vs National Debt</h3>
          </div>
          {/* Profile Picture and Author Info */}
          <div className="entry1-author-info">
            <img
              src={`${process.env.PUBLIC_URL}/images/blogauthor_pic.png`}
              alt="Grant Jaffarian"
              className="entry1-author-image"
            />
            <p className="entry1-author-text">Grant Jaffarian | Oct 2024</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog1;
