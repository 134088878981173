import React, { useState } from 'react';
import '../css/Section4.css';

function Section4() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const questions = [
    "Is the app out yet?",
    "What exactly do I use the app for?",
    "Lots of apps exist what is unique about AddWork?",
    "Is it web-based? Is it Apple Store? Android?",
    "Can I try the beta?",
  ];

  const answers = [
    `The app will launch in early 2025`,
    `The ADDWORK app lets you make change order and work orders on your 
    phone. Add pictures, 
    costs, or a summary. An email goes to your client, who 
    can approve the work order. The dashboard helps 
    you keep track of all your work orders easily. This way, 
    you’ll always know what's happening with your client, 
    and you won’t have to wonder if they agreed to the 
    changes after the job is done.`,
    `There are many construction apps out there, and 
    some of them are really good at managing projects. 
    But they often have more features than we need, are 
    too complicated, cost too much, and might even need 
    a full-time person just to keep them updated. Sometimes 
    simple things, like a change order, can be done, but 
    they're too tricky or get missed. ADDWORK is an easy 
    and clean way to keep track of change orders. You 
    won’t need extra help, and you won’t be confused. 
    You’ll have a simple way to track change orders 
    and work orders all in one place without spending too much.`,
    `AddWork is available as a web-based platform and 
    will also be available for the Apple Store and Android.`,
    `Yes, you can try the beta version! Contact us for more 
    information on how to get access.`,
  ];

  return (
    <div className="App-content-section4">
      <h2 className="Section4-header">FAQ'S</h2>
      <div className="Section4-faq-container">
        {questions.map((question, index) => (
          <div key={index} className="Section4-faq-item">
            <div className="Section4-question" onClick={() => handleToggle(index)}>
              {question}
              <span className={`Section4-arrow ${activeIndex === index ? 'open' : ''}`}>
                ▼
              </span>
            </div>
            {activeIndex === index && (
              <div className="Section4-answer">
                {answers[index]}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Section4;

