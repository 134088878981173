import React, { useState } from 'react';
import '../css/Section2_BEntry1.css';

function Section2_BEntry1() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState('');

  const openModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalImageSrc('');
  };

  return (
    <div className="Section2-BEntry1-container">
      <div className="Section2-BEntry1-content">
        <div className="Section2-BEntry1-left">

            <h1 className="Section2-BEntry1-heading1">Introduction</h1>
            <p>
            As of Q3 2024, the U.S. deficit stands at a staggering $35.7 trillion. 
            But what does that even mean anymore? We’ve grown numb to these astronomical 
            numbers. Washington shuts down, the debt ceiling is extended, money magically 
            appears, and it’s business as usual. But where is that money coming from? 
            And more importantly, is it even real?</p>
            <p>
            Meanwhile, inflation skyrocketed to 9.1% in June 2022, one of the highest 
            levels in recent history. We all felt it—whether it was in our electricity 
            bills or the outrageous rates for babysitters. The Federal Reserve? They’ve 
            taken the brunt of the blame. But is it really that simple?</p>
            <p>
            With so much noise from pundits and talking heads, it’s hard to cut through 
            and understand what all of this means, especially for our industry in the 
            long run. Higher interest rates slow down growth, but if it’s that simple, 
            why not just lower them again? The truth is, the details matter. We need to 
            pull back the curtain on the inflation vs. deficit tug-of-war to really understand 
            the cards that are left to play.</p>
            <p>
            More importantly, how does this impact our business decisions? Should we take 
            on that extra job? Should we leverage more, or stay conservative? Do these 
            trillion-dollar deficits even matter anymore? Or—could this time really be different?</p>
            <img
              src={`${process.env.PUBLIC_URL}/images/b1-1.png`}
              alt="Tug of War Intro"
              className="BEntry1-image"
              onClick={() => openModal(`${process.env.PUBLIC_URL}/images/b1-1.png`)}
            />
            <h2 className="Section2-Bentry1-heading2">The Deficit</h2>
            <p>
            As introduced in the intro, the U.S. deficit sits at a staggering $35.7 trillion— a 
            number so large that it might as well be Monopoly money. But is it? To grasp what it 
            really means, we need more context. A number on its own doesn’t tell the whole story. 
            It’s a number so staggering as to be senseless and make most of us dismissive.  But 
            when you place two related numbers side by side, the impact of these trillions starts 
            to feel real.</p>
            <p>
            So let’s introduce a second number: the rolling annual U.S. GDP, which hit $29 trillion 
            as of Q3 2024. This is the total value of all goods and services produced in the U.S. 
            over the past year—everything from food, to cars, to consultants, to car washes and 
            everything in between. But this isn't the amount the U.S. government brings in through 
            taxes; it’s the total value generated by the country as we all pick up our literal and 
            figurative hammers and do work every year.</p>

            <p>
            To put this into perspective, let’s imagine the U.S. government as a typical household. 
            Say our household has $357,000 in debt, reflecting the U.S. deficit (scaling down from 
            $35.7 trillion), and produces $290,000 in value each year (paralleling GDP at $29 trillion). 
            This doesn’t mean our salary is $290k, just that our household creates that much in value—our utility. 
            Now, let's assume we earn about $200,000 from that utility. Not bad, right?</p>
            <p>
            But here’s where things get tricky. Just like many households, we have debt—mortgages, 
            car loans, maybe some credit card debt—and we need to pay interest on that. Let’s assume 
            the interest rate is 5%. Five percent of $357k is $17,850 a year, which sounds manageable 
            against our $200k salary—just around 10% of what we earn.</p>
            <p>
            Now, let’s zoom back out to the U.S. government. The reality is, the U.S. doesn't turn 
            70% of its GDP into revenue like our household does. In fact, in 2023, government revenue 
            was $4.9 trillion, or just around 17% of GDP. If our household were operating like the U.S. 
            government, that would mean we’re only earning $70,000 a year off our $290k in utility, not 
            $200k. </p>
        </div>
        <div className="Section2-BEntry1-right">
          <p>Suddenly, that $17.8k annual interest on our debt looks a lot scarier—it’s over 25% 
          of our total income!</p>

          <p>
          And that’s before taxes, utilities, or even groceries. Those numbers don’t just make you 
          uncomfortable—they paint a much more serious picture.  In case we forget, that doesn’t 
          include paying down the principal to begin with and if we don’t pay down the balance, just 
          the interest, it’s simply going to get more scary by the year.</p>
          <p>
          But the U.S. government doesn’t have household expenses, right? So, does the analogy really 
          hold up? Well, here’s the thing—it’s true the government doesn’t have typical groceries or 
          utilities bills, unfortunately, it has far greater financial commitments. Just to name the 
          big ticket items with hundreds and thousands underneath them, the government covers 
          healthcare, Social Security, and by far the largest military budget in the world.</p>
          <p>
          When we break down the numbers, the U.S. debt relative to GDP starts to feel like a much 
          bigger deal. We’ve managed to ignore it for years because, until recently, it felt manageable. 
          Back in 2000, the ratio of debt to GDP was just 33% ($3.4 trillion in debt vs. $10.25 
          trillion in GDP). Fast forward to today, and that ratio is 123%—$35.7 trillion in debt 
          compared to $29 trillion in GDP. That’s a dramatic shift.</p>
          <p>
          Now, consider this: with the Federal Funds Rate at apr 5.00%, the U.S. is on the hook for 
          $1.75 trillion just in interest payments. In reality, they should avoid that large of a bill 
          because of bond laddering, however, for sake of discussion we can use that number as a proxy 
          as that is the long term cost ultimately.  That $1.7 trillion number is before paying down any 
          of the principal. And remember, the total government revenue in 2023 was $4.9 trillion.</p>
          <p>
          Let’s return to the household analogy. Imagine your take-home pay is $49,000. Your interest 
          payments on debt are $17,200. You’re left with $31,800. Sure, you could pay down some of the 
          principal, but that principal is $357,000. Even if you put 100% of your remaining income 
          toward it, it would take you 10 years to pay off the debt—leaving you homeless in the process. 
          Spread it over 30 years? You’d be paying $11,000 annually, or more than a third of your income. 
          Where would you come up with that kind of money?</p>
          <p>
          Every year without significant change, the situation worsens. Financing the debt already exceeds 
          the current budget by a wide margin. Even with corrections, experts project that the U.S. deficit 
          could hit $50 trillion+ within the next decade. Right now, there simply isn’t enough money to 
          support all initiatives and cover the interest payments on the national debt. It’s not 
          possible. Full stop</p>
          <p>
          The U.S. government does have options, though. They’re similar to what any household might face:</p>
          <ul className="entry1-blog-list1">
              <li>Make more money</li>
              <li>Reduce spending</li>
              <li>Lower the interest rate on the debt</li>
          </ul>
          <p>
          That’s it. There is one other option.  The other option is exactly what the US Government will 
          do with close to 100% certainty.  And it might work, but it can only work for a little while 
          longer.  How long “a little while” in terms of months or years is subject to tremendous debate.  
          Either way, the only other option is a very bad idea in the medium to long term.  That other 
          idea is simply to keep spending and increase the debt even further.  More on that and why 
          it’s a pretty bad idea in future posts.</p>

          <img
            src={`${process.env.PUBLIC_URL}/images/b1-2.png`}
            alt="Deficit"
            className="BEntry1-image2"
            onClick={() => openModal(`${process.env.PUBLIC_URL}/images/b1-2.png`)}
          />
        </div>
      </div>

      {isModalOpen && (
        <div className="image-overlay" onClick={closeModal}>
          <span className="close-overlay">&times;</span>
          <img src={modalImageSrc} alt="Expanded" className="expanded-image" />
        </div>
      )}
    </div>
  );
}

export default Section2_BEntry1;


/* 
      <img
        src={`${process.env.PUBLIC_URL}/images/b1-3.png`}
        alt="Deficit continued"
        className="blog-image"
      />

        <p></p>
        <ul className="entry1-blog-list1">
            <li>Make more money</li>
            <li>Reduce spending</li>
            <li>Lower the interest rate on the debt</li>
        </ul>
        <p>
        That’s it. There is one other option.  The other option is exactly what the US Government will 
        do with close to 100% certainty.  And it might work, but it can only work for a little while 
        longer.  How long “a little while” in terms of months or years is subject to tremendous debate.  
        Either way, the only other option is a very bad idea in the medium to long term.  That other 
        idea is simply to keep spending and increase the debt even further.  More on that and why 
        it’s a pretty bad idea in future posts.</p>
      <img
        src={`${process.env.PUBLIC_URL}/images/b1-4.png`}
        alt="Inflation"
        className="blog-image"
      />

      <h2>Inflation continued...</h2>
      <p>This brings us full circle...</p>
      <img
        src={`${process.env.PUBLIC_URL}/images/b1-5.png`}
        alt="Inflation continued"
        className="blog-image"
      /> */