import React, { useState } from 'react';
import '../css/Section3_BEntry1.css';
import { Link } from 'react-router-dom';

function Section3_BEntry1() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState('');

  const openModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalImageSrc('');
  };

  return (
    <div className="S3-BEntry1-container">
      <div className="S3-BEntry1-Bcontainer">
        <div className="S3-BEntry1-content">
          <div className="S3-BEntry1-left">
              <h1 className="S3-BEntry1-heading1">Inflation</h1>
              <p>
              For decades, the U.S. Federal Reserve has targeted a 2% inflation rate for Americans. 
              But what does that number actually mean? In reality, it’s just a goal—no one really 
              holds the Fed accountable for hitting exactly 2%, and there’s plenty of debate about 
              whether that target is even the right one. Does it really matter that much?</p>
              <p>
              One thing is for sure: inflation is very real. Put simply, it means that the price 
              of things today won’t be the price of things tomorrow. The rate at which prices go 
              up? That’s inflation. But it’s not as simple as a single number—prices for some things 
              rise faster than others. It gets even trickier when you factor in essentials like 
              food and energy, which can fluctuate for reasons unrelated to domestic U.S. policies.</p>
              <p>
              Regardless of what drives inflation, we know one thing: a high inflation rate hits hard. 
              For decades before COVID-19, inflation hovered around 2%. But that all changed. 
              Inflation peaked at 9.1% in June 2022 and has since cooled to 2.4% as of September 
              2024. So, are we in the clear? Not necessarily.</p>
              <img
                  src={`${process.env.PUBLIC_URL}/images/b1-3.png`}
                  alt="Tug of War Intro"
                  className="S3-BEntry1-image"
                  onClick={() => openModal(`${process.env.PUBLIC_URL}/images/b1-3.png`)}
              />
              <p>
              What recent numbers hide is that while energy prices have dropped by 7% and food prices 
              have risen by only 2.3%, inflation is still running high elsewhere. When you strip out 
              food and energy, core inflation is still at 3.3%. Shelter costs? Up 4.9% annually. 
              Transportation, insurance, and maintenance? They’ve risen even more—and they’re not 
              slowing down.</p>
              <p>
              So, let’s ask the same question we did when discussing the deficit: does it matter? On 
              this point, we don’t need a complex debate. It matters. Inflation affects all of us, 
              and it hurts. What makes it worse? Wage growth hasn’t remotely kept up with inflation. 
              So let’s be clear: inflation matters to you, to me—it matters a lot.</p>

              <h2 className="S3-Bentry1-heading2">Inflation Part II</h2>            
              <p>
              To the Federal Reserve, inflation matters a lot. Why? Because it impacts the economy 
              in a huge way. When the economy is doing well, generally speaking, so are the rest of 
              us. Everyone’s a bit more relaxed. But when the economy struggles? Well, we all know 
              what that looks like.</p>
              <p>
              High inflation signals that the economy isn’t doing well—full stop. It might seem like 
              things are fine because the stock market is up, but here’s the thing: the stock market 
              doesn’t reflect whether everyday people are doing better. It shows whether investors 
              (who typically have more resources than the average person) are making more money or 
              seeing their investments grow. You can grow your portfolio by paying workers less, 
              and that’s part of the reason wage growth hasn’t kept pace with inflation recently.</p>
              <p>
              The core problem with high inflation is simple: things cost more. It’s basic supply 
              and demand—one of the closest things economics has to a law. As prices rise, demand 
              falls. Less demand means businesses sell less, leading to lower revenues and rising 
              inventories. And storing unsold goods? That costs money. Just like how we wouldn’t 
              buy lumber or steel without a plan to build, companies face similar challenges. It’s 
              too expensive to hold onto products without selling them.</p>

              <p>
              When companies make less, they look to reduce costs. And where do they usually start? 
              Their highest expense: people. Cutting jobs or reducing wages causes a ripple effect—people 
              buy even less. A bit of inflation can be managed, but high inflation for too long? 
              That’s when things start to spiral.</p>
              <p>For households, the choices mirror those of the government facing the national debt: 
                  (i) cut spending; (ii) find a way to make more money; or, the most common path: 
                  (iii) roll that rock uphill and finance what you can’t afford. And that’s where 
                  things get tricky.</p>
              <h3 className="S3-Bentry1-heading3">Inflation Part III</h3>   
              <p>
              This brings us full circle. If the U.S. debt burden gets too high, it can spiral just 
              like consumer credit card debt. Think about it: if you only pay the minimum on your 
              credit card and never touch the principal, you’re not getting ahead—you’re sinking 
              further behind. The same is true with national debt. Without reducing the principal, 
              you’re just accruing more and more interest.</p>
              <p>
              Now, let’s talk about inflation. If you don’t address it early, you can end up in an 
              economic downward spiral. Inflation, if left unchecked, makes everyday things more 
              expensive—groceries, rent, gas. And once it’s high enough, the problem compounds: 
              costs keep going up, wages don’t keep pace, and before long, the economy is in trouble. 
              At a certain point, it’s likely that things get worse, not better.</p>
              <p>
              So, what are the options for dealing with inflation before it spirals out of control? 
              The Federal Reserve only has one major tool in its belt—interest rates. The Fed can’t 
              force individuals to spend less, and it can’t control whether the government cuts back 
              on its own spending. But it can control how expensive it is to borrow money. By raising 
              or lowering interest rates, the Fed can influence how much it costs to refinance debt, 
              effectively “pushing that debt uphill” to make it cheaper when the interest bill comes due.</p>
              <p>
              Here’s the problem: when the Fed lowers rates, it makes it cheaper for everyone to borrow 
              money. This is what increases the money supply—not by literally creating new money, but 
              by making it cheaper to borrow and use other people’s money instead of your own. This 
              artificial boost in borrowing can temporarily stimulate demand, but the flip side is 
              that it keeps prices rising. If everyone has more access to borrowed cash, demand stays 
              high or grows and inflation flares up like forgotten embers in dry brush  The more 
              demand, the higher the prices.</p>
              <p>
              On the other hand, if the Fed raises interest rates, people have less money to spend 
              because borrowing becomes more expensive. This can cool inflation, but it also risks 
              slowing down the entire economy. Higher rates mean businesses borrow less, invest less, 
              and may even start cutting jobs to save money. Worse, raising rates also makes it more 
              expensive to finance our own personal debts.</p>
          </div>
          <div className="S3-BEntry1-right">



              <p>  And wouldn’t you know it, it also makes 
              financing the existing national debt, which means the U.S. itself also has to spend 
              more just to pay off interest on its obligations.</p>
              <p>
              The key takeaway is that managing inflation and debt is a delicate balance. Lower rates 
              can keep the economy moving but risk higher inflation. Higher rates can combat inflation 
              but risk triggering a recession—and increase the cost of servicing our massive national debt.</p>
              <p>
              The U.S. government does have options, though. They’re similar to what any household might face:</p>
              <h4 className="S3-Bentry1-heading4">U.S. Debt in the Crosshairs</h4>   
              <p>
              So far, we’ve established that the U.S. deficit is becoming dangerously unmanageable. Twenty 
              years ago, it wasn’t a major issue unless you wanted to make it one. Even ten years ago, 
              the situation wasn’t alarming, not in a truly “Houston, we have a problem” way. But 
              today? When we stop to consider what it means, few could make an argument we have nothing 
              to worry about.</p>
              <p>
              Before we dive into solutions, let’s touch briefly on a scenario we’ll explore more in 
              future ADDWORK posts: the idea that the government could just keep piling on debt. What 
              happens if we say, “to hell with it” and keep borrowing? Well, we soon face two choices, 
              neither of which is good: (i) print more money, or (ii) default on the debt.  Before 
              continuing, it is worth noting that we believe with near 100% certainty financing debt with 
              even more debt is the path that will be taken.  The problem is that path is by definition, 
              temporary.  So we will “play out” briefly the impact of that inevitable choice.  However, 
              we will spend the bulk of this blog discussing the options when, which is also inevitable, 
              just taking on more debt is impossible.  That time is coming - perhaps now, perhaps in 10 
              years, but the end is right there in sight.
              </p>

              <p>
              The first option—printing money—means firing up the presses and creating dollars from thin 
              air. Can you actually do that?  Well, not literally.  Actual paper money makes up less than 
              1% of all money, you’re not moving the needle.  The way you do it is by issuing government 
              debt.  As in, here good friend, can I borrow 1 billion US dollars from you government debt.  
              But that waters down the value of the existing money. And that brings us back to inflation.
              </p>
              <p>
              Why inflation?  Because if there’s more money, each dollar has less value and manufacturers 
              want more money for each item.  But paying back that made up money?  That’s a nightmare 
              scenario because it signals that the government can't be trusted to manage the economy 
              responsibly.  It can’t be trusted because you could loan the government money (and “you” 
              is often China or other major economies) and then they turn around and make all that money 
              they owe you far less valuable.  Do you want to go for another loan after that?  At a certain 
              point, no.  And if no more debt is available that magical printing press doesn’t work anymore.  Game over.
              </p>
              
              <p>
              The second option—defaulting on debt—is equally disastrous. As discussed, the U.S. finances 
              its spending by selling Treasury bills (essentially loans from investors).  Above we discuss 
              what happens if we just inflate that loan so it’s less valuable.  Eventually people don’t 
              want to lend us anything. Just as bad or worse, if the government stops paying people back 
              altogether, who would ever buy those Treasury bills again? The result is literally, this is 
              not hyperbole, the collapse of our entire monetary system. There’s no way around it.
              </p>
              <h5 className="S3-Bentry1-heading5">Debt Crosshairs II</h5>       
              <p>
              So, if taking on more debt without consequences is off the table, what’s next? The U.S. 
              government has three realistic options to manage the debt:
              </p>
              <ul className="S3-entry1-list1">
                <li>Make more money | increase revenue</li>
                <li>Spend less money | reduce expenditures</li>
                <li>Lower the interest rates | push the financing "rock" up the hill</li>
              </ul>
              <p>
              Let’s break these down.
              </p>
              <img
              src={`${process.env.PUBLIC_URL}/images/b1-4.png`}
              alt="Deficit"
              className="S3-BEntry1-image2"
              onClick={() => openModal(`${process.env.PUBLIC_URL}/images/b1-4.png`)}
              />
              <p>
              Option 1: Make more money. The biggest lever for increasing government revenue is taxation. 
              You could double taxes and that would certainly make a dent. For instance, if tax revenue 
              jumped from $4.9 trillion to $9.8 trillion, the U.S. would be in a better position to cover 
              its interest payments, which are approximately $1.5 to $2 trillion annually. That feels more 
              manageable, right?
              </p>
              <p>
              But here’s the catch: even doubling taxes would only bring the income-to-interest ratio to 
              5:1, which is far worse than it was just four years ago. That’s still like a household’s 
              finances being 20% just the interest payment on debt.  Ouch.  And doubling taxes? That has 
              serious consequences. Households and businesses would have far less disposable income, likely 
              leading to a sharp drop in consumer spending. And when spending drops? The risk of stagflation 
              (a stagnant economy with high inflation) becomes real. And let’s be honest—without a miracle, 
              that’s not a path we want to walk.
              </p>
              <p>
              Option 2: Spend less. To move the needle here, the government would need to cut spending by 
              10-40%—an enormous reduction. And let’s face it, making cuts of that magnitude is incredibly 
              challenging. Most government spending is tied up in non-negotiable programs like Social Security, 
              Medicare, and defense, leaving very little room for trimming the fat. So, let’s move on to the 
              third and, frankly, most viable option.
              </p>
              <p>
              Option 3: Lower interest rates. This is, by far, is the most practical solution. By lowering 
              interest rates, the cost of carrying the national debt becomes cheaper. It’s a tried-and-true 
              method for reducing the financial burden of debt—both for governments and households alike. 
              The Federal Reserve has the ability to influence these rates, and while it comes with its own 
              set of risks, it’s often the most palatable option for managing high debt levels without crushing the economy.
              </p>
          </div>

        </div>
        <Link to="/blog/tug-of-war2" className="read-entry2">READ PART II</Link>
      </div>
      {isModalOpen && (
        <div className="image-overlay" onClick={closeModal}>
          <span className="close-overlay">&times;</span>
          <img src={modalImageSrc} alt="Expanded" className="expanded-image" />
        </div>
      )}
    </div>
  );
}

export default Section3_BEntry1;