import React from 'react';
import '../css/Section2.css';

function Section2() {
  return (
    <div className="App-content-section2">
      <div className="Sec2-top">
          <div className="Sec2-top-titles">
            <div className="Sec2-title1">
              THE PROBLEM
            </div>
            <div className="Sec2-title2">
              WE'RE SOLVING
            </div>
          </div>
          <div className="Sec2-top-text">
          Complex construction software is everywhere. But the simplest needs 
          get lost. Change orders were always a challenge, even with the best 
          tools. It's time for a simple, intuitive, and affordable solution.
          </div>
      </div>

      <div className="Sec2-bottom">
        <div className="Sec2-left">
          <div className="Sec2-left-words">
              <div className="S2-left-title">
                Submit work orders from the palm of your hand.
              </div>
              <div className="S2-left-text">
              We were tired of searching through texts and emails to 
              track change orders. We wanted an easy, on-the-spot solution 
              to keep everything organized.
              </div>
          </div>
          <div className="Section2-img1">
            <img src={`${process.env.PUBLIC_URL}/images/Section2_img1.png`} alt="Sec2 1"/>
          </div>
        </div>

        <div className="Sec2-right">
          <div className="Section2-img2">
            <img src={`${process.env.PUBLIC_URL}/images/Section2_img2.png`} alt="Sec2 1"/>
          </div>
          <div className="Sec2-right-words">
            <div className="S2-right-title">
              Easily sort by job site or client.
            </div>
            <div className="S2-right-text">
            Your client approved the work, but changes can be tricky. 
            With our dashboard, you can easily track, sort, and 
            access all approvals. Invoicing is stress-free with the knowledge 
            that your client has seen and approved what you're billing.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
