import React from 'react';
import Header from './Header';
import Section5 from './Section5';
import Section1_About from './Section1_About'; // Import the customized Section1 for Our Story
import Section2_About from './Section2_About';
import Section3_About from './Section3_About';
import Section4_About from './Section4_About';
import Section5_About from './Section5_About';
import '../css/About.css';

function About() {
  return (
    <div className="About-page">
      <Header />
    <div className="About-main">
      <Section1_About />
      <Section2_About />
      <Section3_About />
      <Section4_About />
      {/* <Section5_About /> */}
      <Section5 /> {/* Same Section5 from the homepage */}
    </div>
    </div>
  );
}

export default About;
