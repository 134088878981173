import React from 'react';
import '../css/Section2_About.css';

function Section2_About() {
    return (
        <div className="section2-about-container">
            <h3 className="trusted-by">As Seen In:</h3>
            <div className="logos-container">
                <div className="logo-item">
                    <img src={`${process.env.PUBLIC_URL}/images/DCH.png`} alt="DCH Logo" />
                </div>
                <div className="logo-item">
                    <img src={`${process.env.PUBLIC_URL}/images/everwild.png`} alt="Everwild Logo" />
                </div>
                <div className="logo-item">
                    <img src={`${process.env.PUBLIC_URL}/images/alphaterra.png`} alt="Alphaterra Logo" />
                </div>
                <div className="logo-item">
                    <img src={`${process.env.PUBLIC_URL}/images/foundry.png`} alt="Foundry Logo" />
                </div>
            </div>
        </div>
    );
}

export default Section2_About;
