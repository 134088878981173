import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Section1_OurStory.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

function Section1_OurStory() {
  return (
    <div className="os1-App-content-OurStorysection1">

        {/* Header section at the top of Section1 */}
        <header className="os1-Section1-headero">
            <nav className="os1-App-navo">
            <div className="os1-App-logoo">
                <Link to="/">
                <img 
                    src={`${process.env.PUBLIC_URL}/images/AddWrk_logo_white.png`} 
                    alt="ADDWRK Logo" 
                />
                </Link>
            </div>

            <ul className="os1-App-menuo">
                <li><Link to="/our-story">OUR STORY</Link></li>
                <li><Link to="/about">ABOUT</Link></li>
                <li><Link to="/blog">BLOG</Link></li>
                <li><Link to="/contact">CONTACT</Link></li>
            </ul>

            <div className="os1-Merch-download-signino">
                <Link to="/company">
                <img 
                    src={`${process.env.PUBLIC_URL}/images/Download_buttonWB.png`} 
                    alt="Download Button" 
                    className="os1-Downloado"
                />
                </Link>
                <Link to="/company" className="os1-Signino">SIGN IN</Link>
                <div className="os1-Merch-icono">
                <FontAwesomeIcon icon={faCartShopping} />
                </div>
            </div>
            </nav>
        </header>

        {/* New content for Our Story */}
        <div className="os1-App-content-section1-ourstory">
            {/* Background Image */}
            <div className="os1-Section1-ourstory-background">
                <img 
                    src={`${process.env.PUBLIC_URL}/images/story_top.png`} 
                    alt="Our Story Background" 
                    className="os1-story-image"
                />
            </div>
            {/* Overlay content */}
            <div className="os1-Section1-ourstory-content">
                <h1 className="os1-Page-title-os">OUR STORY</h1>
                <div className="os1-quote-content">
                    <p className="os1-quote1">"There was</p>
                    <p className="os1-quote2">something about</p>
                    <p className="os1-quote3">showing up at a job</p>
                    <p className="os1-quote4">site at 6:30 AM."</p>
                    <p className="os1-quote-author">KEN SUNZERI | ADDWORK CO-FOUNDER</p>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Section1_OurStory;
