import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Section1.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

function Section1() {
  return (
    <div className="App-content-section1">
      {/* Header section at the top of Section1 */}
      <header className="Section1-header">
        <nav className="App-nav">
          {/* App Logo - occupies 30% */}
          <div className="App-logo">
            <Link to="/">
              <img 
                src={`${process.env.PUBLIC_URL}/images/AddWrk_logo_white.png`} 
                alt="ADDWRK Logo" 
              />
            </Link>
          </div>

          {/* App Menu - occupies 40% */}
          <ul className="App-menu">
            <li><Link to="/our-story">OUR STORY</Link></li>
            <li><Link to="/about">ABOUT</Link></li>
            <li><Link to="/blog">BLOG</Link></li>
            <li><Link to="/contact">CONTACT</Link></li>
          </ul>

          {/* Merch, Download Image, Sign In - occupies 30% */}
          <div className="Merch-download-signin">
            <Link to="/company">
              <img 
                src={`${process.env.PUBLIC_URL}/images/Download_button.png`} 
                alt="Download Button" 
                className="Download"
              />
            </Link>
            <Link to="/company" className="Signin">SIGN IN</Link>
            <div className="Merch-icon">
              <FontAwesomeIcon icon={faCartShopping} />
            </div>
          </div>
        </nav>
      </header>

      {/* Rest of Section1 content */}
      <div className="Line1">
        <div className="highlighted-word1">ADD WORK</div>
      </div>

      <div className="Line2">
        <div className="highlighted-word2">NOT WORRIES</div>
        <div className="tagline">
          AddWork is intuitive, beautiful and secure. 
          With AddWork you'll compose, submit and receive approval for projects and change 
          orders in seconds.
        </div>
      </div>

      {/* Store Links */}
      <div className="Stores">
        <div className="Apple-Hidden">
          <Link to="/">
            <img src={`${process.env.PUBLIC_URL}/images/AppleStore.png`} alt="Apple App Store" className="Apple-hidden" />
          </Link>
        </div>

        <div className="Apple-Store">
          <Link to="/">
            <img src={`${process.env.PUBLIC_URL}/images/AppleStore.png`} alt="Apple App Store" className="Apple-image" />
          </Link>
        </div>

        <div className="Google-Store">
          <Link to="/">
            <img src={`${process.env.PUBLIC_URL}/images/GooglePlay.png`} alt="Google Play" className="Google-image" />
          </Link>
        </div>
      </div>
      
      <div className="App-image-container">
        <img src={`${process.env.PUBLIC_URL}/images/phone_login.png`} alt="Phone Mockup" className="App-image" />
      </div>
    </div>
  );
}

export default Section1;



// DELETED CODE
// <div className="Opt_in">
// <img src={`${process.env.PUBLIC_URL}/images/sms_email.png`} alt="SMS Optin" className="SMS-image" />
// <div className="DisclaimerBox1">
//   <div className="Disclaimer1">The app is currently in development and will implement a clear opt-in process for SMS notifications at launch. Users will create an account and will be presented with a checkbox labeled, "I agree to receive SMS notifications about work orders." This checkbox will be positioned directly after the app's terms and conditions. Users must actively check the box in order to receive SMS messages. If they do not check the box, they will not receive any SMS notifications. The default option is set to not receive SMS messages. This ensures users must actively consent to messaging.</div>
//   <div className="Disclaimer2">Screenshots or mock-ups of the opt-in process are available upon request. The app is scheduled to launch in January 2025, and this opt-in process will be fully implemented at that time.</div>
// </div>
// </div>