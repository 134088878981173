import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Section1_About.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

function Section1_About() {
    return (
      <div className="App-content-Aboutsection1">
  
          <header className="Section1-headera">
              <nav className="App-nava">
                  <div className="App-logoa">
                      <Link to="/">
                          <img 
                              src={`${process.env.PUBLIC_URL}/images/AddWrk_logo_white.png`} 
                              alt="ADDWRK Logo" 
                          />
                      </Link>
                  </div>
  
                  <ul className="App-menua">
                      <li><Link to="/our-story">OUR STORY</Link></li>
                      <li><Link to="/about">ABOUT</Link></li>
                      <li><Link to="/blog">BLOG</Link></li>
                      <li><Link to="/contact">CONTACT</Link></li>
                  </ul>
  
                  <div className="Merch-download-signina">
                      <Link to="/company">
                          <img 
                              src={`${process.env.PUBLIC_URL}/images/Download_button.png`} 
                              alt="Download Button" 
                              className="Downloada"
                          />
                      </Link>
                      <Link to="/company" className="Signina">SIGN IN</Link>
                      <div className="Merch-icona">
                          <FontAwesomeIcon icon={faCartShopping} />
                      </div>
                  </div>
              </nav>
          </header>
  
          {/* Video Section */}
          <div className="About-top">
              <div className="About-video">
                  <iframe 
                      width="100%" 
                      height="auto" 
                      src="https://www.youtube.com/embed/mybDkwFaes4?si=krntsSfYe76YLDRv" 
                      title="YouTube video player" 
                      frameBorder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                      referrerPolicy="strict-origin-when-cross-origin" 
                      allowFullScreen
                  ></iframe>
              </div>

              {/* About Box with Store Buttons */}
              <div className="top-info">
                  <img 
                      src={`${process.env.PUBLIC_URL}/images/about_s1.png`} 
                      alt="About box" 
                      className="About-s1-img"
                  />
                  <div className="about-stores">
                      <img
                          src={`${process.env.PUBLIC_URL}/images/AppleStore.png`} 
                          alt="Apple Store"
                          className="Store-button"
                      />
                      <img
                          src={`${process.env.PUBLIC_URL}/images/GooglePlay.png`} 
                          alt="Google Play Store"
                          className="Store-button"
                      />
                  </div>
              </div>
          </div>
      </div>
    );
}

export default Section1_About;


