import React, { useState } from 'react';
import '../css/Section3_BEntry2.css';

function Section3_BEntry2() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState('');

  const openModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalImageSrc('');
  };

  return (
    <div className="S3-BEntry2-container">
      <div className="S3-BEntry2-content">
        <div className="S3-BEntry2-left">
            <h1 className="S3-BEntry2-heading1">Everyone! Start Pulling II</h1>
            <p>
            A soft landing is the ideal scenario. Imagine this: we manage to thread the needle. We raise government revenue just enough to make a difference, but not enough to stifle the economy with heavy taxes on businesses or households. At the same time, we cut government spending, forcing politicians to make tough decisions—meaning not every request from their constituents can be fulfilled. The third piece? Lowering interest rates to reduce the cost of financing debt. If we can pull this off, we build optimism. People start to believe, “Hey, maybe we can get through this.” The budget gets balanced, the economy stabilizes, and we avoid mass layoffs or a surge in household debt. It’s a delicate dance, but with the right moves, it seems possible.</p>
            <p>
            But is it? What if this time it’s not possible, thanks to the tug-of-war between inflation, interest rates, and debt? Let’s think back to the COVID-19 pandemic. The economy only recovered because we flooded it with money and lowered rates to unprecedented levels. When inflation reared its head, we tackled it by raising rates and doubling down on government spending. And so far, we’ve been told it’s working—that we’re dancing between the raindrops, dodging disaster.</p>
            <p>
            But markets are jumpy, and instinct tells us something isn’t quite right. Despite the headlines blaring, “Everything’s fine,” there’s a growing sense of unease. What happens if job growth slips again? Or worse—inflation ticks up, or GDP takes another hit? We’ve already seen signs of that.</p>
            <img
                src={`${process.env.PUBLIC_URL}/images/b2-hold.png`}
                alt="Tug of War Intro"
                className="S3-BEntry2-image"
                onClick={() => openModal(`${process.env.PUBLIC_URL}/images/b2-hold.png`)}
            />
            <p>
            Now, let’s talk about the other possibility: a hard landing. This is a different beast. Picture an old machine—cogs and wheels that are barely holding together. It keeps turning until one crack forms, then another, and soon the whole thing starts to fall apart faster than we can fix it. In a hard landing, we attempt the same moves as the soft landing, but everything unravels. Instead of gliding through the storm, we stumble. Maybe inflation spikes unexpectedly, or attempts to raise revenue choke off growth. Each fix just makes things worse. Suddenly, inflation spirals, government revenue backfires, and living costs shoot through the roof.</p>
            <p>
            To regain control, we’re forced to raise interest rates again. But that move triggers an economic downturn. With less money to spend, businesses falter, layoffs increase, and the economy shrinks. The spiral deepens.</p>

            <p>
            This isn’t just a recession—it’s something much worse. It feels deeper. This is what we’d call a depression.</p>
            <h2 className="S3-Bentry2-heading2">How Does One Respond In the Construction Industry?</h2>            
            <p>
            In a soft landing, our industry should come out relatively unscathed. In fact, you could argue that if the Federal Reserve can consistently lower interest rates without sparking inflation—something essential for a soft landing—our industry should keep borrowing and spending. Why? Because the cost of financing debt will drop, not rise.</p>
            <p>
            But in a hard landing, taking on debt becomes a risky move. If your financing costs rise beyond what your income and sales can cover, you could end up with nothing left after paying off those debts. Worse yet, rising interest rates shrink household consumption—demand for new homes and renovations plummets, and so do prices. And if you think that’s bad, remember that the U.S. government’s debt will face the same squeeze, only on a far larger scale. Regardless of political alignment, that should concern everyone.</p>
            <p>
            A popular safeguard often touted is diversification. Large firms point to their varied portfolios—projects spanning residential, commercial, and infrastructure contracts across the globe. They have partnerships across various contract structures: lump-sum, cost-plus, and design-build. But in a hard landing, none of that will matter. Diversification in a downturn often lulls companies into a false sense of security—right before everything collapses.</p>
            </div>
            <div className="S3-BEntry2-right">
            <p>
            However, that false sense of security is exactly why there’s so much opportunity in a hard landing. In a downturn, diversification can spread out risk, but strategic targeting is where the real wins come. Everyone feels the tug-of-war, but within any given area—take residential real estate as an example—there will still be segments with untapped opportunities. If your margin-to-equity management is sound, those opportunities become extraordinary deals. Ultimately, there’s no substitute for skill. Being diversified doesn’t require much skill—just a large portfolio. But navigating uncertain times requires being open to the idea that things might not be as stable as they seem. If one of those raindrops hits and starts a downward spiral, the companies that are prepared and nimble will be ready to capitalize on the moment.</p>
            <p>
            At ADDWORK, we’re not trying to be financial advisors. But we’re keeping a close eye on this tug-of-war. We believe it’s naïve to think the U.S. economy and government will get through this without some serious shocks. Our role in the industry is simple: to make all of our lives easier. We’re starting that journey by refining how our industry handles Change Orders. Like these posts, our goal is to lay out the information in a way that’s clear, unbiased, and transparent. After all, transparency is the only way to make the most informed decisions.</p>
            <h3 className="S3-Bentry2-heading3">The Magic Bullet</h3>   
            <p>
            At ADDWORK, we’re optimists at heart. If you’ve been following our blog series, Tug-a-War, it wouldn’t be surprising if you’re feeling a bit disheartened. The current deficit and inflationary environment is, well, a pickle. But there’s one potential solution that stands out—so much so that it might be worth betting on: significant technological advancement.</p>
            <p>
            In posts 1-3, we outlined three possible solutions to the debt crisis: (i) spend less; (ii) make more; and (iii) reduce the financing costs of U.S. debt. The idea of “making more” was quickly dismissed because, under current conditions, it’s hard to imagine moving the needle enough to make a difference. But that’s where technological advancement comes in.</p>
            <p>
            There’s a chance that a breakthrough could fundamentally reshape the way we produce GDP in the U.S.—and potentially worldwide. Several emerging technologies could make this happen. At the forefront are innovations like artificial intelligence, breakthroughs in protein folding or synthetic materials, or even massive energy production advancements like cold fusion.</p>
            <p>
            There’s historical precedent for this kind of shift. Major innovations like the semiconductor, the automobile, the computer, and the internet didn’t just create incremental change. They transformed industries, economies, and entire ways of life. The same thing could happen again. If we see a breakthrough in technology, we might not just see gradual growth; we could see a paradigm shift—an entire step-change in how the economy functions.</p>
            <p>
            Imagine, for a moment, that energy becomes unlimited. What would that do to property values in places like Las Vegas when desalination becomes virtually free? What happens when autonomous vehicles eliminate the need for parking garages, and those spaces can be converted into revenue-generating hubs?</p>
            <p>
            We don’t claim to have all the answers—maybe not even any of them. But we believe it’s critical to stay open to all possibilities. Even with a technological breakthrough that could fundamentally change the economic landscape, timewill still be needed to bridge the gap to that future. A lot can happen between now and then, and simply shutting down isn’t the solution. If we can’t navigate this tug-of-war between inflation and debt, skill and thoughtfulness will matter far more than size or diversification. After all, if the U.S. government defaults, it’s hard to imagine anyone being truly “too big to fail.” The one thing we are confident of? The future will surprise us. It may not be easy to imagine a scenario where we can’t dance through the storm, but those willing to envision that possibility might find themselves well-rewarded.
            </p>
            <img
            src={`${process.env.PUBLIC_URL}/images/b2-hold.png`}
            alt="Deficit"
            className="S3-BEntry2-image2"
            onClick={() => openModal(`${process.env.PUBLIC_URL}/images/b2-hold.png`)}
            />
        </div>
      </div>

      {isModalOpen && (
        <div className="image-overlay" onClick={closeModal}>
          <span className="close-overlay">&times;</span>
          <img src={modalImageSrc} alt="Expanded" className="expanded-image" />
        </div>
      )}
    </div>
  );
}

export default Section3_BEntry2;
