import React from 'react';
import '../css/Section4_About.css';

function Section4_About() {
    return (
        <div className="section4-about-container">
            <div className="about4-text">
                <h4 className="section4-heading">WHO IT'S FOR</h4>
                <h2 className="section4-title">ANYBODY THAT WANTS TO ACCURATELY TRACK WORK AGREEMENTS MADE 
                WITH CUSTOMERS AND CLIENTS</h2>
                <p className="section4-description">
                    AddWork is designed both as a standalone solution for one-person-artisans and/or a supplemental 
                    tool that can easily be built-into large teams' long established habits.
                </p>
            </div>
            <div className="person-cardsy-container">
                <div className="person-cardy">
                    <div className="cardy-front">
                        <h5>ONE PERSON SHOP</h5>
                        <p>It’s nearly impossible to track daily jobsite changes in real time, 
                            but I can’t stop progress on the project to catch up on paperwork. 
                            </p>
                    </div>
                    <div className="cardy-back">
                        <img src={`${process.env.PUBLIC_URL}/images/about4_card1.png`} alt="Person 1" />
                    </div>
                </div>
                <div className="person-cardy">
                    <div className="cardy-front">
                        <h5>A LARGE ENTERPRISE</h5>
                        <p>Weekly office meetings help my team of 15 project managers keep me 
                            up to date on the status of each project. However, I know I’m 
                            losing a lot of money on small changes that have to get done to 
                            keep the project moving.</p>
                    </div>
                    <div className="cardy-back">
                        <img src={`${process.env.PUBLIC_URL}/images/about4_card2.png`} alt="Person 2" />
                    </div>
                </div>
                <div className="person-cardy">
                    <div className="cardy-front">
                        <h5>A SMALL TEAM</h5>
                        <p>Our small family run design firm juggles multiple clients and 
                            daily jobsite meetings. AddWork allows me to track new work 
                            requests, and share them on their unique dashboard with my 
                            son and daughter, so they know what has been approved.
                        </p>
                    </div>
                    <div className="cardy-back">
                        <img src={`${process.env.PUBLIC_URL}/images/about4_card3.png`} alt="Person 3" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section4_About;
