import React from 'react';
import '../css/Section2_OurStory.css';

function Section2_OurStory() {
    return (
        <div className="section2-ourstory-container">
            <div className="story-image-container">
                <img 
                    src={`${process.env.PUBLIC_URL}/images/story2_pic.png`} 
                    alt="Kenneth Sunzari" 
                    className="story-image"
                />
            </div>
            <div className="story-text-container">
                <h2 className="story-heading">MEET OUR CO-FOUNDER</h2>
                <h3 className="founder-name">KEN SUNZERI</h3>
                <p className="story-description">
                    I began working in construction the summer I turned fourteen. A neighbor managing 
                    a large apartment renovation needed help with manual labor, and soon a friendly carpenter 
                    took me under his wing, gifting me an old DeWalt set—a Sawzall, Skilsaw, and drill.
                </p>
                <p className="story-description">
                    It was 1999, and each tool seemed to weigh 25 pounds, but I loved it. There was 
                    something about showing up at a job site at 6:30 AM, with the sun barely up and 
                    a hot cup of coffee in hand.
                </p>
                <p className="story-description">
                    It’s a blue-collar energy that only fellow tradesmen understand—a sense of belonging 
                    to a club whose members start before dawn and often work past sunset.
                </p>
                <p className="story-description highlight">
                    I’ve always felt lucky to be part of it.
                </p>
            </div>
        </div>
    );
}

export default Section2_OurStory;
