import React from 'react';
import Header from './Header';
import Section5 from './Section5';
import Blog1 from './Section1_BEntry1'; // Correct import here
import Section2_BEntry1 from './Section2_BEntry1';
import Section3_BEntry1 from './Section3_BEntry1';
import '../css/BEntry1.css';

function BEntry1() {
  return (
    <div className="B1-page">
      <Header />
      <div className="B1-main">
        <Blog1 /> {/* Rendering Blog1, not BEntry1 */}
        <Section2_BEntry1 />
        <Section3_BEntry1 />
        <Section5 />
      </div>
    </div>
  );
}

export default BEntry1;
