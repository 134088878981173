import React from 'react';
import Header from './Header';
import Section5 from './Section5';
import Section1_Blog from './Section1_Blog';
import Section2_Blog from './Section2_Blog';
import '../css/Blog.css';

function Blog() {
  return (
    <div className="Blog-page">
      <Header />
    <div className="Blog-main">
      <Section1_Blog />
      <Section2_Blog />
      <Section5 /> {/* Same Section5 from the homepage */}
    </div>
    </div>
  );
}

export default Blog;
