import React from 'react';
import { Link } from 'react-router-dom';

function BlogEntry({ imageSrc, title, text, authorentryImage, authorentryDescription, linkTo }) {
  return (
    <div className="blog-entry">
      {/* Wrap image inside a Link */}
      <Link to={linkTo}>
        <img src={imageSrc} alt={title} className="entry-image" />
      </Link>
      <h1 className="entry-title">{title}</h1>
      <p className="entry-text">{text}</p>
      <div className="author-entry-container">
        <img src={authorentryImage} alt="author" className="author-entry-image" />
        <h1 className="author-entry-description">{authorentryDescription}</h1>
      </div>
    </div>
  );
}

export default BlogEntry;