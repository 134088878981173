import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Section1_Blog.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

function Section1_Blog() {
  return (
    <div className="App-content-Blogsection1">

        {/* Header section at the top of Section1 */}
        <header className="Section1-headerb">
            <nav className="App-navb">
            {/* App Logo - occupies 30% */}
            <div className="App-logob">
                <Link to="/">
                <img 
                    src={`${process.env.PUBLIC_URL}/images/AddWrk_logo_red.png`} 
                    alt="ADDWRK Logo" 
                />
                </Link>
            </div>

            {/* App Menu - occupies 40% */}
            <ul className="App-menub">
                <li><Link to="/our-story">OUR STORY</Link></li>
                <li><Link to="/about">ABOUT</Link></li>
                <li><Link to="/blog">BLOG</Link></li>
                <li><Link to="/contact">CONTACT</Link></li>
            </ul>

            {/* Merch, Download Image, Sign In - occupies 30% */}
            <div className="Merch-download-signinb">
                <Link to="/company">
                <img 
                    src={`${process.env.PUBLIC_URL}/images/Download_buttonh.png`} 
                    alt="Download Button" 
                    className="Downloadb"
                />
                </Link>
                <Link to="/company" className="Signinb">SIGN IN</Link>
                <div className="Merch-iconb">
                <FontAwesomeIcon icon={faCartShopping} />
                </div>
            </div>
            </nav>
        </header>

 
        {/* New content for Blog */}
        <div className="App-content-section1-blog">
            {/* Background Image */}
            <div className="Section1-blog-background">
                <img 
                    src={`${process.env.PUBLIC_URL}/images/blog1_pic.png`} 
                    alt="Blog Background" 
                    className="blog-image"
                />
            </div>
            {/* Overlay content */}
            <div className="Section1-blog-content">
                <h1 className="Page-blog-title">FEATURED BLOG</h1>
                <div className="blog-title-holder">
                    <h2 className="Feature-title">Tug-of-War | </h2>
                    <h3 className="Feature-title2">ARE WE SCREWED?<br />Inflation vs National Debt</h3>
                </div>
                <p className="Feature-text">
                    As of Q3 2024, the U.S. deficit stands at a staggering $35.7 trillion. 
                    But what does that even mean anymore? We’ve grown numb to these astronomical 
                    numbers. Washington shuts down, the debt ceiling is extended, money 
                    magically appears, and it’s business as usual.</p>
                    <Link to="/blog/tug-of-war" className="feature-more-button">READ MORE</Link>
            </div>
        </div>
    </div>
  );
}

export default Section1_Blog;
