import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Section3_About.css';

function Section3_About() {
    return (
        <div className="section3-about-container">
            <div className="about-text">
                <h4 className="section-heading">WHAT WE DO</h4>
                <h2 className="section-title">WORK ORDER & CHANGE ORDER MANAGEMENT</h2>
                <p className="section-description">
                Simply and easily create work and change orders IN SECONDS, regardless of the 
                size. Send them to your client, and track them in our easy to navigate company 
                dashboard.
                </p>
                <Link to="/our-story" className="our-story-button">OUR STORY</Link>
            </div>
            <div className="feature-cards-container">
                <div className="card-top-row">
                    <div className="card">
                        <div className="card-front">
                            {/* Replacing checkmark with image */}
                            <img src={`${process.env.PUBLIC_URL}/images/checkmark.png`} alt="Checkmark" className="checkmark-image" />
                            <h5>Feature 1</h5>
                            <p>Send Work orders to client WHEN the work is added or changed</p>
                        </div>
                        <div className="card-back">
                            <img src={`${process.env.PUBLIC_URL}/images/feature1.png`} alt="DCH Logo" />
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-front">
                            {/* Replacing checkmark with image */}
                            <img src={`${process.env.PUBLIC_URL}/images/checkmark.png`} alt="Checkmark" className="checkmark-image" />
                            <h5>Feature 2</h5>
                            <p>Receive QUICK approval from clients for your work</p>
                        </div>
                        <div className="card-back">
                            <img src={`${process.env.PUBLIC_URL}/images/feature2.png`} alt="Everwild Logo" />
                        </div>
                    </div>
                </div>
                <div className="card-bottom-row">
                    <div className="card">
                        <div className="card-front">
                            {/* Replacing checkmark with image */}
                            <img src={`${process.env.PUBLIC_URL}/images/checkmark.png`} alt="Checkmark" className="checkmark-image" />
                            <h5>Feature 3</h5>
                            <p>Track all work orders on a SIMPLE dashboard</p>
                        </div>
                        <div className="card-back">
                            <img src={`${process.env.PUBLIC_URL}/images/feature3.png`} alt="Alphaterra Logo" />
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-front">
                            {/* Replacing checkmark with image */}
                            <img src={`${process.env.PUBLIC_URL}/images/checkmark.png`} alt="Checkmark" className="checkmark-image" />
                            <h5>Feature 4</h5>
                            <p>Track when work orders are complete and get PAID</p>
                        </div>
                        <div className="card-back">
                            <img src={`${process.env.PUBLIC_URL}/images/feature4.png`} alt="Foundry Logo" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section3_About;
