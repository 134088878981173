import React from 'react';
import '../css/Section3_OurStory.css';

function Section3_OurStory() {
  return (
    <div className="section3-os3-container">

      {/* Section 1 */}
      <div className="section3-os3-content1">
        
        <div className="red-os3-line1"></div>
        
        <div className="image-os3-container1">
          <img
            src={`${process.env.PUBLIC_URL}/images/story_q1.png`}
            alt="How it started"
            className="story-os3-image1"
          />
        </div>
        <div className="text-os3-container1">
            <p className="story-os3-description1">
                Throughout high school, I worked in the trades sporadically and then 
                more regularly afterward, when I enrolled in community college night 
                classes. By nineteen, I had become a competent carpenter and had 
                solid experience setting tile.
            </p>
            <p className="story-os3-description1">
                With a mix of experience and blissful ignorance, I decided to start my 
                own business. I put an ad in the PennySaver mailer and the largest 
                county newspaper, and soon my phone was ringing off the hook.
            </p>
            <p className="story-os3-description1">
                My construction and remodeling business was born. It took a couple 
                more years to get my contractor’s license, but I found myself in one 
                of the best regions for custom construction: the greater Bay Area.
            </p>
            <p className="story-os3-description1">
                Putting one foot in front of the another, slowly I grew our company to 
                become a prominent builder of large luxury estates in the region.
            </p>
        </div>
      </div>

      {/* Section 2 */}
      <div className="section3-os3-content2">

        <div className="red-os3-line2"></div>

        <div className="image-os3-container2">
          <img
            src={`${process.env.PUBLIC_URL}/images/story_q2.png`}
            alt="The problem"
            className="story-os3-image2"
          />
        </div>
        <div className="text-os3-container2">
            <p className="story-os3-description2">
                At twenty-two, I experienced my first major financial loss. I was hired 
                by a larger builder to install interior trim for a project in Cupertino, 
                CA. The scope expanded to include wall paneling, tiling a bathroom, 
                and a large floor.
            </p>
            <p className="story-os3-description2">
                My final invoice for the additional work came to twelve thousand 
                dollars, which was meant to be pure profit as I had already paid 
                out-of-pocket for all the materials and extra labor.
            </p>
            <p className="story-os3-description2">
                That money would be enough to cover three months of living expenses 
                at the time. Weeks passed, but the payment never arrived. When I 
                finally reached out, the builder claimed that the extra work had never 
                been formally agreed upon, and my contract was “paid in full.”
            </p>
            <p className="story-os3-description2">
                No matter how much I argued, I hit a brick wall. I even approached 
                the homeowner, but they insisted I deal with the builder. Out of 
                options, I placed a lien on the property and filed a small claims court.
            </p>
        </div>
      </div>

      {/* Section 3 */}
      <div className="section3-os3-content3">

      <div className="red-os3-line3"></div>

        <div className="image-os3-container3">
          <img
            src={`${process.env.PUBLIC_URL}/images/story_q3.png`}
            alt="The solution"
            className="story-os3-image3"
          />
        </div>
        <div className="text-os3-container3">
            <p className="story-os3-description3">
                A few weeks later, I received a letter from the homeowners' attorney 
                demanding $2,500 to cover legal fees or signed proof of the 
                agreement for the additional work.
            </p>
            <p className="story-os3-description3">
                I learned a harsh lesson: without a documented agreement, the work 
                may as well have never happened. In my eagerness to keep the job 
                moving, I hadn’t taken the time to draft a change order, and that 
                oversight ended up costing me dearly.
            </p>
            <p className="story-os3-description3">
                I swore I'd never make that mistake again, but decades later, the 
                change order process remains a struggle after nearly every build. 
                We use the best software available, but some things always gets lost in 
                translation, or the process is too cumbersome to complete in the 
                moment.
            </p>
            <p className="story-os3-description3">
                One day, I was venting to a friend about the endless Change Order headaches, 
                he laughed: 'Well, if all the big-time software solutions can't get you out of 
                Excel and beyond sorting emails and texts, why don’t we just build the thing 
                ourselves?' Getting stuff done is one thing I love more than anything. 
                So that’s exactly what my co-founder Grant Jaffarian and I set out to do.
            </p>
        </div>
      </div>
    </div>
  );
}

export default Section3_OurStory;
