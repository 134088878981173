import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation from react-router-dom
import '../css/Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation(); // useLocation hook to detect route changes

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth > 768) {
        if (window.scrollY > 350) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      } else {
        setScrolled(true); // Always true for smaller screens
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    // Run once to set the initial state
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Reset the menu state when route changes
    setMenuOpen(false);
  }, [location]); // Triggers when the location (route) changes

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className={`App-headerh ${scrolled ? 'visible' : 'hidden'}`}>
      <nav className="App-navh">
        {/* App Logo - occupies left side */}
        <div className="App-logoh">
          <Link to="/">
            <img 
              src={`${process.env.PUBLIC_URL}/images/${scrolled ? 'AddWrk_logo_red' : 'AddWrk_logo_white'}.png`} 
              alt="ADDWRK Logo" 
            />
          </Link>
        </div>

        {/* Burger Icon for mobile menu */}
        <div className="Burger-iconh" onClick={toggleMenu}>
          <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
        </div>

        {/* App Menu - for larger screens */}
        <ul className={`App-menuh ${menuOpen ? 'open' : ''}`}>
          <li><Link to="/our-story">OUR STORY</Link></li>
          <li><Link to="/about">ABOUT</Link></li>
          <li><Link to="/blog">BLOG</Link></li>
          <li><Link to="/contact">CONTACT</Link></li>
          <li><Link to="/company" className="Signinhh">SIGN IN</Link></li>
          <li><Link to="/company" className="Merch-link">MERCHANDISE</Link></li>
        </ul>

        {/* Merch, Download Image, Sign In - occupies right side for larger screens */}
        <div className="Merch-download-signinh">
          <Link to="/company">
            <img 
              src={`${process.env.PUBLIC_URL}/images/Download_buttonh.png`} 
              alt="Download Button" 
              className="Downloadh"
            />
          </Link>
          <Link to="/company" className="Signinh">SIGN IN</Link>
          <div className="Merch-iconh">
            <FontAwesomeIcon icon={faCartShopping} />
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;












// OLD CODE BEFORE BURGER DROPDOWN
// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom'; 
// import './Header.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

// function Header() {
//   const [scrolled, setScrolled] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY > 350) {
//         setScrolled(true);
//       } else {
//         setScrolled(false);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   return (
//     <header className={`App-headerh ${scrolled ? 'visible' : 'hidden'}`}>
//       <nav className="App-navh">
//         <div className="App-logoh">
//           <Link to="/">
//             <img 
//               src={`${process.env.PUBLIC_URL}/images/${scrolled ? 'AddWrk_logo_red' : 'AddWrk_logo_white'}.png`} 
//               alt="ADDWRK Logo" 
//             />
//           </Link>
//         </div>

//         <ul className="App-menuh">
//           <li><Link to="/our-story">OUR STORY</Link></li>
//           <li><Link to="/about">ABOUT</Link></li>
//           <li><Link to="/blog">BLOG</Link></li>
//           <li><Link to="/contact">CONTACT</Link></li>
//         </ul>

//         <div className="Merch-download-signinh">
//           <Link to="/company">
//             <img 
//               src={`${process.env.PUBLIC_URL}/images/Download_buttonh.png`} 
//               alt="Download Button" 
//               className="Downloadh"
//             />
//           </Link>
//           <Link to="/company" className="Signinh">SIGN IN</Link>
//           <div className="Merch-iconh">
//             <FontAwesomeIcon icon={faCartShopping} />
//           </div>
//         </div>
//       </nav>
//     </header>
//   );
// }

// export default Header;
