import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Footer.css';

function Footer() {
  return (
    <footer className="App-footer">
      <div className="footer-content">
        <div className="footer-left">
          {/* Wrap the logo in a Link component */}
          <Link to="/">
            <img 
              src={`${process.env.PUBLIC_URL}/images/addwrk-redwhite.png`} 
              alt="ADDWRK Logo" 
              className="footer-logo" 
            />
          </Link>
          <ul className="footer-menu">
            <li><Link to="/our-story">Our Story</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/blog">Blog</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
          <p>FOLLOW US ON SOCIAL:</p>
          <ul className="social-icons">
            <li><a href="https://www.facebook.com/addworknotworries?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/images/facebook_logo.png`} alt="Facebook" />
            </a></li>
            <li><a href="https://www.instagram.com/addworkapp?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/images/instagram_logo.png`} alt="Instagram" />
            </a></li>
            <li><a href="https://www.tiktok.com/@addworknotworries" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/images/tiktok_logo.png`} alt="TikTok" />
            </a></li>
            <li><a href="https://www.linkedin.com/company/addwrk" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/images/linkedin_logo.png`} alt="LinkedIn" />
            </a></li>
            <li><a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/images/twitterx_logo.png`} alt="Twitter" />
            </a></li>
            <li><a href="https://www.youtube.com/@addworknotworries" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/images/youtube_logo.png`} alt="YouTube" />
            </a></li>
          </ul>

        </div>
        <div className="footer-right">
          <p>DOWNLOAD THE APP TODAY</p>
          <div className="app-links">
            <img 
              src={`${process.env.PUBLIC_URL}/images/AppleStore.png`} 
              alt="App Store" 
              className="app-store-apple" 
            />
            <img 
              src={`${process.env.PUBLIC_URL}/images/GooglePlay.png`} 
              alt="Google Play" 
              className="app-store-google" 
            />
          </div>
          <p className="footer-copyright">©2024 AddWork | Have Questions? info@AddWork.com</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
