import React, { useEffect, useRef } from 'react';
import Header from './Header';
import Section4 from './Section4';
import Section5 from './Section5';
import Section1_Contact from './Section1_Contact'; 
import '../css/Contact.css';

function Contact() {
  const contactAnchorRef = useRef(null);

  useEffect(() => {
    // Check if the URL contains #contact-anchor and scroll to the element
    if (window.location.hash === '#contact-anchor' && contactAnchorRef.current) {
      // Scroll to the element first
      contactAnchorRef.current.scrollIntoView({ behavior: 'smooth' });

      // After the smooth scroll, adjust the position by an additional 300px
      setTimeout(() => {
        window.scrollBy({ top: 300, left: 0, behavior: 'smooth' });
      }, 500); // Delay to ensure initial scroll finishes
    }
  }, []);

  return (
    <div className="Contact-page">
      <Header />
      <div className="Contact-main">
        
        {/* Anchor point */}
        <div id="contact-anchor" ref={contactAnchorRef}></div>

        <Section1_Contact /> 
        <Section4 />
        <Section5 /> 
      </div>
    </div>
  );
}

export default Contact;
