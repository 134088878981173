import React from 'react';
import { Link } from 'react-router-dom';
import BlogEntry from './BlogEntry'; // Import the reusable BlogEntry component
import '../css/Section2_Blog.css';

function Section2_Blog() {
  const blogEntries = [
    {
      imageSrc: `${process.env.PUBLIC_URL}/images/blogentry1_pic.png`,
      title: 'Tug-of-War | Are we screwed?',
      text: `As of Q3 2024, the U.S. deficit stands at a staggering 
            $35.7 trillion.  But what does that even mean anymore? 
            We’ve grown numb to these astronomical numbers.`,
      authorentryImage: `${process.env.PUBLIC_URL}/images/blogauthor_pic.png`,
      authorentryDescription: 'Grant Jaffarian | Oct 2024',
      linkTo: "/blog/tug-of-war"
    },
    {
      imageSrc: `${process.env.PUBLIC_URL}/images/blogentry2_pic.png`,
      title: 'Tug-of-War  |  Try this? FAIL.',
      text: `The first option—printing money—means firing up the presses 
      and creating dollars from thin air. Can you actually do that?  
      Not literally  ... you’re not moving the needle.`,
      authorentryImage: `${process.env.PUBLIC_URL}/images/blogauthor_pic.png`,
      authorentryDescription: 'Grant Jaffarian | Oct 2024',
      linkTo: "/blog/tug-of-war2"
    },
    {
      imageSrc: `${process.env.PUBLIC_URL}/images/blogentry3_pic.png`,
      title: 'Coming Soon | more blogs.',
      text: `Coming soon. Fresh blogs will come soon and we really hope 
      you check back soon. Much more exciting content then what has so 
      far appeared will be here shortly.`,
      authorentryImage: `${process.env.PUBLIC_URL}/images/blogauthor_pic.png`,
      authorentryDescription: 'Grant Jaffarian | Nov 2024',
    },
    {
      imageSrc: `${process.env.PUBLIC_URL}/images/blogentry3_pic.png`,
      title: 'Coming Soon | more blogs.',
      text: 'This is for Blog4 his is for Blog4 his is for Blog4.',
      authorentryImage: `${process.env.PUBLIC_URL}/images/blogauthor_pic.png`,
      authorentryDescription: 'Grant Jaffarian | Dec 2024',
    },
    {
        imageSrc: `${process.env.PUBLIC_URL}/images/blogentry3_pic.png`,
        title: 'Coming Soon | more blogs.',
        text: 'This is for Blog5 his is for Blog5 his is for Blog5.',
        authorentryImage: `${process.env.PUBLIC_URL}/images/blogauthor_pic.png`,
        authorentryDescription: 'Grant Jaffarian | Jan 2025',
      },
      {
        imageSrc: `${process.env.PUBLIC_URL}/images/blogentry3_pic.png`,
        title: 'Coming Soon | more blogs.',
        text: 'This is for Blog6 his is for Blog6 his is for Blog6.',
        authorentryImage: `${process.env.PUBLIC_URL}/images/blogauthor_pic.png`,
        authorentryDescription: 'Grant Jaffarian | Feb 2025',
      },
  ];

  return (
    <div className="section2-blog-container">
        <div className="section2-blog-title">RECENT BLOG POSTS</div>
        <div className="blog-posts-container">
            {blogEntries.map((entry, index) => (
            <BlogEntry
                key={index}
                imageSrc={entry.imageSrc}
                title={entry.title}
                text={entry.text}
                authorentryImage={entry.authorentryImage}
                authorentryDescription={entry.authorentryDescription}
                linkTo={entry.linkTo} // Pass the link to BlogEntry
            />
            ))}
        </div>
        <div className="blog-button-container">
            <Link to="/blog" className="more-blogs-button">LOAD MORE BLOGS</Link>
        </div>
    </div>
  );
}

export default Section2_Blog;