import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/Section1_Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

const Section1_Contact = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = () => {
    setIsSubmitted(true);  // Display success message after form submission
  };

  return (
    <div className="App-content-Contactsection1">
      <header className="Section1-headerc">
        <nav className="App-navc">
          <div className="App-logoc">
            <Link to="/">
              <img 
                src={`${process.env.PUBLIC_URL}/images/AddWrk_logo_red.png`} 
                alt="ADDWRK Logo" 
              />
            </Link>
          </div>
          <ul className="App-menuc">
            <li><Link to="/our-story">OUR STORY</Link></li>
            <li><Link to="/about">ABOUT</Link></li>
            <li><Link to="/blog">BLOG</Link></li>
            <li><Link to="/contact">CONTACT</Link></li>
          </ul>
          <div className="Merch-download-signinc">
            <Link to="/company">
              <img 
                src={`${process.env.PUBLIC_URL}/images/Download_buttonh.png`} 
                alt="Download Button" 
                className="Downloadc"
              />
            </Link>
            <Link to="/company" className="Signinc">SIGN IN</Link>
            <div className="Merch-iconc">
              <FontAwesomeIcon icon={faCartShopping} />
            </div>
          </div>
        </nav>
      </header>

      <div className="Contact-top">
        <div className="Contact-topbox">
          <div className="topbox-img">
            <img 
              src={`${process.env.PUBLIC_URL}/images/contact_box.png`} 
              alt="PC logo" 
            />
          </div>
          <div className="topbox-links">
            <div className="button1">
              <a href="mailto:info@addwork.com">
                <img 
                  src={`${process.env.PUBLIC_URL}/images/more_information.png`} 
                  alt="info logo"
                  className="interactive-button"
                />
              </a>
            </div>
            <div className="button2">
              <a href="mailto:support@addwork.com">
                <img 
                  src={`${process.env.PUBLIC_URL}/images/customer_support.png`} 
                  alt="info logo"
                  className="interactive-button"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="Contact-form-section">
        <h2>PLEASE REACH OUT BELOW</h2>
        {!isSubmitted ? (
          <form 
            className="Contact-form" 
            action="https://formsubmit.co/b43971baa67ac05afa591340726c8f7c" 
            method="POST" 
          >
            <select name="inquiry-type" required className="Contact-form-select">
              <option value="" disabled selected>Select an inquiry type</option>
              <option value="about">Tell me more about what ADDWORK does</option>
              <option value="support">I have a support-related inquiry</option>
              <option value="pricing">I want to know about cost and pricing</option>
              <option value="other">Other</option>
            </select>
            <input type="hidden" name="_next" value="https://www.addwork.com/contact"></input>
            <input type="hidden" name="_captcha" value="false" />
            <input type="text" name="name" placeholder="Name" required />
            <input type="text" name="company" placeholder="Company name (optional)" />
            <input type="email" name="email" placeholder="Email" required />
            <textarea name="message" placeholder="Message" required></textarea>
            <button type="submit">SUBMIT</button>
          </form>
        ) : (
          <p>Thank you! Your message has been sent.</p>
        )}
      </div>
    </div>
  );
}

export default Section1_Contact;
