import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Section5.css';

function Section5() {
  return (
    <div className="App-content-section5">
      <div className="Section5-content">
        <div className="Section5-left">
          <div className="Section5-header">
            <h2 className="Section5-headera">READY TO </h2>
            <h2 className="Section5-headerb">GET STARTED?</h2>
            <Link to="/contact#contact-anchor" className="S5-contact-button">CONTACT US</Link>
          </div>
            
        </div>
      </div>
      <div className="Section5-right">
        <img 
          src={`${process.env.PUBLIC_URL}/images/Section5_screenshot.png`} 
          alt="Section5 Screenshot" 
          className="Section5-screenshot" 
        />
      </div>
    </div>
  );
}

export default Section5;
