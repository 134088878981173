import React, { useState } from 'react';
import '../css/Section3.css';

function Section3() {
  const [currentIndex, setCurrentIndex] = useState(1); // Set the initial index to the middle box

  const testimonials = [
    {
      text: `My wife and I are busy building a swim school in town. 
      It's an intimidating process for new investors/clients in a big project! 
      But having a simple tool where we can track our requests and changes
      will make any construction project or home improvement instantly easier
      for us as a client.  Sure, other large software packages exist, but really
      all we wanted was to be able to ask for modifications and quickly get a quote
      and be able to approve or deny our requests.  Not a lot but AddWork makes it
      easy.`,
      name: 'Grant Jaffarian',
      title: 'Investor | AquaTerra',
      image: `${process.env.PUBLIC_URL}/images/face1.png`,
    },
    {
      text: `I started in construction as a teenager, working long days 
      and learning the ropes. Though scared to take on my own projects, 
      I pushed through. Two decades later, I'm grateful for all I've 
      learned. But the issue that my team and I still can't handle well? Change orders. They're always a hassle with 
      endless back-and-forth. I’ve tried big industry apps, 
      but they're often too complex or need dedicated admins. I wanted a 
      simple solution, for us and for everyone. I hope 
      you'll give us a try!`,
      name: 'John Knauss',
      title: 'CEO | Deschuttes Custom Homes',
      image: `${process.env.PUBLIC_URL}/images/face1.png`,
    },
    {
      text: `With a beta launch, AddWork will look to receive real-world
      feedback and improve the performance of the product to meet the needs
      of ALL users.  This means not just large General Contractors but especially all
      the absolutely essential sub-contractors we all lean on so aggressively
      to get jobs done.  Check back soon for early user feedback!`,
      name: 'Beta User',
      title: 'Sub-Contractor | Independent',
      image: `${process.env.PUBLIC_URL}/images/face1.png`,
    },
  ];

  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="App-content-section3">
      {/* Background logo positioned below */}
      <img
        src={`${process.env.PUBLIC_URL}/images/AddWrk_simplelogo_red.png`}
        alt="Background Logo"
        className="Section3-background-logo"
      />
      <div className="Section3-content-wrapper">
        <div className="Section3-header"></div>
        <div className="Section3-carousel">
          <button className="Section3-arrow-left" onClick={handleLeftClick}>
            <img src={`${process.env.PUBLIC_URL}/images/arrow_left.png`} alt="Left Arrow" />
          </button>
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className={`Section3-dialogue-box ${
                index === currentIndex ? 'active' : index === (currentIndex - 1 + testimonials.length) % testimonials.length ? 'left' : 'right'
              }`}
            >
              <p className="Section3-author-text">{testimonial.text}</p>
              <div className="Section3-author">
                <img src={testimonial.image} alt={testimonial.name} className="Section3-author-image" />
                <div className="Section3-author-details">
                  <div className="Section3-author-name"><strong>{testimonial.name}</strong></div>
                  <div className="Section3-author-title">{testimonial.title}</div>
                </div>
              </div>
            </div>
          ))}
          <button className="Section3-arrow-right" onClick={handleRightClick}>
            <img src={`${process.env.PUBLIC_URL}/images/arrow_right.png`} alt="Right Arrow" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Section3;