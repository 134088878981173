import React from 'react';
import Header from './Header';
import Section5 from './Section5';
import Blog2 from './Section1_BEntry2'; // Correct import here
import Section2_BEntry2 from './Section2_BEntry2';
import Section3_BEntry2 from './Section3_BEntry2';
import '../css/BEntry2.css';

function BEntry2() {
  return (
    <div className="B2-page">
      <Header />
      <div className="B2-main">
        <Blog2 /> {/* Rendering Blog1, not BEntry1 */}
        <Section2_BEntry2 />
        <Section3_BEntry2 />
        <Section5 />
      </div>
    </div>
  );
}

export default BEntry2;
